import React from "react";
import { NavLink } from "react-router-dom";

export default function OurTeam() {
  return (
    <div>
      <section className="section-team">
        <div className="container">
          {/* Header Section */}
          <div className="row justify-content-center text-center">
            <div className="col-md-10 col-lg-8">
              <div className="header-section">
                <h2 className="display-6 text-center mb-4">                  
                </h2>
                <div class="header-section">                
                <h2 className="display-6 text-center mb-4">Meet <b>Our</b> Team </h2>            
                </div>
                <p className="lead text-center">
                  Our team consists of skilled professionals dedicated to delivering exceptional results.
                </p>
              </div>
            </div>
          </div>
          
          {/* Team Members */}
          <div className="row">
            {/* Team Member Card */}
            <div className="col-md-6 col-lg-4 mb-4">
              <div className="team-member-card">
                <img src="/assets/team/AnandS.jpg" alt="John Doe" className="team-photo"/>
                <div className="card-body">
                  <h3 className="title">Anand Samudra</h3>
                  <p className="role">Founder & Managing Director</p>
                  <p className="description">
                    Anand is a visionary leader with over 15 years of experience in the IT industry, guiding our company to success.<br />
                    Strategic Technology Visionary Driving Digital Transformation, Fostering Innovation, and Propelling Technological Excellence in Organization.
                  </p>
                  <NavLink to="../team/AnandS" className="profile-link">
                    View Profile
                  </NavLink>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 mb-4">
              <div className="team-member-card">
                <img src="/assets/team/SachinB.jpg" alt="Jane Smith" className="team-photo"/>
                <div className="card-body">
                  <h3 className="title">Sachin Bhandwalkar</h3>
                  <p className="role">Chief Cyber Security Officer (CCSO)</p>
                  <p className="description">Cyber Security Expert Consultant & Certified Trainer</p>
                  <NavLink to="/team/SachinB" className="profile-link">
                    View Profile
                  </NavLink>
                </div>
              </div>
            </div>

            {/* <div className="col-md-6 col-lg-4 mb-4">
              <div className="team-member-card">
                <img src="/assets/team/AnandS.jpg" alt="Michael Brown" className="team-photo"/>
                <div className="card-body">
                  <h3 className="title">Sunita Samudra</h3>
                  <p className="role">HR Head </p>
                  <p className="description">Michael excels in full-stack development and leads our engineering team with his extensive expertise.</p>
                  <NavLink to="/team/michael" className="profile-link">
                    View Profile
                  </NavLink>
                </div>
              </div>
            </div> */}


          </div>
        </div>
      </section>
    </div>
  );
}
